export function IsObject(value: any) {

    return value !== null
        && typeof value === "object";
}

export function IsFunction(value: any): value is (...args: any[]) => any {

    return typeof value === "function";
}

export function IsNumber(value: any): value is number {

    return typeof value === "number";
}

export function IsString(value: any): value is string {

    return typeof value === "string";
}

export function IsArray(value: any): value is Array<any> {

    return Array.isArray(value)
        || value instanceof Array;
}

export function IsWindow(value): value is Window {

    return value?.window === value;
}

export function IsBlankObject(value: any) {

    return value !== null
        && typeof value === "object"
        && !Object.getPrototypeOf(value);
}

export function IsArrayLike(value: any) {

    if (value == null || IsWindow(value)) return false;
    if (IsArray(value) || IsString(value)) return true;

    // Support: iOS 8.2 (not reproducible in simulator)
    // "length" in obj used to prevent JIT error (gh-11508)
    const length = "length" in Object(value) && value.length;

    // NodeList objects (with `item` method) and
    // other objects with suitable length characteristics are array-like
    return IsNumber(length)
        && (length >= 0 && (length - 1) in value || typeof value.item === "function");
}

export function IsDefined(value: any) {

    return typeof value !== "undefined";
}
